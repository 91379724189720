import React, { useContext } from 'react'
import { Card, Select, Typography } from 'antd'
import { t } from 'i18next'

import SpecialistCalendar from './Calendar'
import SpecialistSlots from './Slot'
import SpecialistContext from './SpecialistContext'
import { Color } from '../../Common/Color'

export default function Specialist () {
  const { defineCalendar, allSpecialists, reason, specialists, allMonthAvailabilities, setReason, reasons, setSpecialist, specialist } = useContext(SpecialistContext)

  const selectSpecialist = (value) => {
    if (value === undefined) {
      defineCalendar(specialists, allMonthAvailabilities)
    } else {
      const selectedSpecialist = allSpecialists.find(e => e.id === value)
      defineCalendar([selectedSpecialist], allMonthAvailabilities)
    }
  }

  const selectReason = (value) => {
    const selectedReason = reasons.find(e => e.id === value)
    setReason(selectedReason)
    setSpecialist(undefined)
  }

  return (
    <>
      {reason &&
        <div className="specialists" style={{ justifyContent: 'space-around', backgroundColor: Color.grey, width: '100%', padding: '3em 0' }}>
          <Card className="select" style={{ width: '30em', height: '60vh', backgroundColor: Color.green, boxShadow: '1px 1px 10px #4f5d75' }} bordered={false}>
            <Typography.Title level={2} style={{ marginBottom: '4em', marginTop: '0', color: Color.white, textAlign: 'center' }}>{t('Public.Specialist.slotSelection')}</Typography.Title>
            <div style={{ width: '25em' }}>
              <Typography.Title level={5} style={{ color: Color.white }}>{t('Public.Specialist.selectReason')}</Typography.Title>
              <Select onChange={selectReason} style={{ width: 'inherit' }} defaultValue={reason?.id}>
                {
                  reasons.map((reason) => (
                    <Select.Option key={reason.id} value={reason.id}>
                      {reason.name}
                    </Select.Option>
                  ))
                }
              </Select>
              <Typography.Title level={5} style={{ color: Color.white }}>{t('Public.Specialist.selectSpecialist')}</Typography.Title>
              <Select placeholder={t('Public.Specialist.automatic')} onChange={selectSpecialist} style={{ width: 'inherit' }} allowClear value={specialist?.id}>
                {
                  specialists && specialists.map((specialist) => (
                    <Select.Option key={specialist.id} value={specialist.id}>
                      {specialist.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
          </Card>
          <div className="days">
            <SpecialistCalendar />
          </div>
          <div className="slots">
            <SpecialistSlots />
          </div>
        </div>
      }
      {reasons?.length === 0 &&
        <div style={{
          boxShadow: '0px 0px 2px 2px #f2f2f2',
          borderRadius: '1em',
          padding: '1em',
          margin: '1em',
          maxWidth: '290px',
          fontSize: '1.2em',
          textAlign: 'center'
        }}>
          <div style={{ color: Color.green, fontWeight: 'bold' }}>
            {t('Public.Specialist.empty')}
          </div>
        </div>
      }
    </>
  )
}
