import { Button, Col, Form, Input, Radio, Row, Space } from 'antd'
import React from 'react'
import { t } from 'i18next'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import PhoneInput from 'react-phone-number-input/input'
import { JOBS } from '../../Common/Common'

export default function IntervenerForm ({ form, request }) {
  const onFinish = (values) => {
    const phones = values.phones?.map((phone) => ({ ...phone, value: phone.value.slice(1) })) || []
    const toDelete = values.fields?.filter(field => !phones.concat(values.emails).map(e => e.id).includes(field.id)) || []
    values.fields_attributes = phones.concat(values.emails).concat(toDelete.map(field => ({ ...field, _destroy: '1' })))
    request(values)
  }

  return (
    <Form
      form={form}
      labelAlign='left'
      onFinish={onFinish}
    >
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='fields' hidden>
        <Input />
      </Form.Item>
      <Form.Item name="job" label={t('Admin.IntervenerForm.job.label')}
        rules={[{ required: true, message: t('rules.required') }]}
      >
        <Radio.Group>
          {JOBS.map((job) => (
            <Radio key={job} value={job}>{t(`Admin.IntervenerForm.job.${job}`)}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Form.Item name="last_name" label={t('Admin.IntervenerForm.lastName')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="first_name" label={t('Admin.IntervenerForm.firstName')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="company" label={t('Admin.IntervenerForm.company')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <h3>{t('Admin.IntervenerForm.phones')}</h3>
      <Form.List name="phones">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8
                }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[{ required: true, message: t('rules.required') }]}
                  label={t('Admin.IntervenerForm.phone')}
                >
                  <PhoneInput className='ant-input css-dev-only-do-not-override-xfszwz' international defaultCountry={'FR'}
                    placeholder="06 39 98 80 12" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'description']}
                  label={t('Admin.IntervenerForm.description')}
                >
                  <Input />
                </Form.Item>
                <Form.Item name={[name, 'id']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={[name, 'field_type']} hidden initialValue={'phone'}>
                  <Input />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {`${t('actions.add')} ${t('Admin.IntervenerForm.aPhone')}`}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <h3>{t('Admin.IntervenerForm.emails')}</h3>
      <Form.List name="emails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8
                }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[{ required: true, message: t('rules.required') }]}
                  label={t('Admin.IntervenerForm.email')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'description']}
                  label={t('Admin.IntervenerForm.description')}
                >
                  <Input />
                </Form.Item>
                <Form.Item name={[name, 'id']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={[name, 'field_type']} hidden initialValue={'email'}>
                  <Input />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {`${t('actions.add')} ${t('Admin.IntervenerForm.anEmail')}`}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}
