import { Button, Form, Layout } from 'antd'
import { t } from 'i18next'
import { parsePhoneNumber } from 'react-phone-number-input'
import React, { useContext, useEffect, useState } from 'react'
import BoxForm from '../BoxesPage/BoxForm'
import BoxContext from './BoxContext'
import GlobalContext from '../GlobalContext'

export default function BoxInfoPage () {
  const [loading, setLoading] = useState(true)
  const { box, owners, updateBox } = useContext(BoxContext)
  const { data } = useContext(GlobalContext)
  const [form] = Form.useForm()

  const canEdit = data.user.roles.includes('admin') || data.user.roles.includes('setup') || data.user.roles.includes('supervisor')

  useEffect(() => {
    if (box) {
      const phone = box.interphone ? parsePhoneNumber(box.interphone, 'FR').number : undefined
      form.setFieldsValue({
        ...box,
        picture: box.picture_url?.url,
        interphone: phone,
        interveners: box.interveners.map(i => ({ ...i, key: i.id }))
      })
      setLoading(false)
    }
  }, [box])

  const onFinish = (values) => {
    updateBox(values, form)
  }

  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
      <h2>{t('Admin.BoxInfoPage.title')}</h2>
      {!loading && <div>
        <BoxForm form={form} onFinish={onFinish} owners={owners} state={canEdit ? 'edit' : 'show'}/>
        { canEdit &&
          <Button type="primary" htmlType="submit" onClick={form.submit}>
            {t('Admin.btn.submit')}
          </Button>
        }
      </div>
      }
    </Layout>
  )
}
