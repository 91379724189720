import { Button, Card, Col, Form, Input, Popover, Radio, Row, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import TextArea from 'antd/es/input/TextArea'
import PhoneInput from 'react-phone-number-input/input'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { CircleMarker, MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import { Color } from '../../Common/Color'
import { UploadOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { get } from '../../Common/api'
import { JOBS } from '../../Common/Common'

export default function BoxForm ({ form, onFinish, owners, state }) {
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState()
  const [mapVisible, setMapVisible] = useState(false)
  const [interveners, setInterveners] = useState([])
  const [displayedInterveners, setDisplayedInterveners] = useState(form.getFieldValue('interveners') || [])
  const [intervener, setIntervener] = useState()
  const [job, setJob] = useState()

  const getInterveners = async () => {
    await get('admin/interveners', {}, (res) => {
      setInterveners(res.map(intervener => ({ ...intervener, key: intervener.id })))
    })
  }

  const addIntervener = () => {
    const addedIntervener = interveners.find(i => i.id === intervener)
    const iArray = [...displayedInterveners, addedIntervener]
    setDisplayedInterveners(iArray)
    form.setFieldValue('interveners', iArray)
    setIntervener(null)
  }

  const removeIntervener = (i) => {
    const iArray = [...displayedInterveners.filter(intervener => intervener.id !== i.id)]
    setDisplayedInterveners(iArray)
    form.setFieldValue('interveners', iArray)
  }

  const displayInterveners = () => {
    return (
      displayedInterveners.map(i => (
        <Row key={i.id} style={{ flexFlow: 'row', marginBottom: '1em' }}>
          <Col span={3} style={{ marginRight: 'auto' }}><Card bodyStyle={{ padding: '3px', textAlign: 'center' }}>
            {t(`Admin.IntervenersList.table.job.${i.job}`)}
          </Card></Col>
          <Col span={6} style={{ marginRight: 'auto' }}><Card bodyStyle={{ padding: '3px', textAlign: 'center' }}>{i.name}</Card></Col>
          {displayCommunicationMeans(i.phones, i.emails)}
          <Button onClick={() => removeIntervener(i)} type="primary" style={{ marginLeft: 'auto' }} shape="circle" icon={<MinusOutlined />} />
        </Row>
      ))
    )
  }

  const displayCommunicationMeans = (phones, emails) => {
    return (
      <>
        <Col span={4} style={{ marginRight: 'auto' }}>
          {phones.map(phone => (
            displayCommunication(phone)
          ))}
        </Col>
        <Col span={6} style={{ marginRight: 'auto' }}>
          {emails.map(email => (
            displayCommunication(email)
          ))}
        </Col>
      </>
    )
  }

  const displayCommunication = (communication) => {
    const value = communication.field_type === 'phone' ? formatPhoneNumber(`+${communication.value}`) : communication.value
    return (
      <>
        {communication.description &&
          <Popover content={communication.description}>
            <Card bodyStyle={{ padding: '3px', textAlign: 'center' }}>{value}</Card>
          </Popover>
        }
        {!communication.description &&
          <Card bodyStyle={{ padding: '3px', textAlign: 'center' }}>{value}</Card>
        }
      </>
    )
  }

  const onSubmit = (values) => {
    if (values.interphone) {
      values.interphone = formatPhoneNumber(values.interphone).replaceAll(' ', '')
    }
    if (values.gps_point) {
      values.gps_point = `POINT(${values.gps_point})`
    } else {
      values.gps_point = null
    }
    onFinish(values)
  }

  useEffect(() => {
    getInterveners()
    setTimeout(() => {
      setMapVisible(true)
    }, 500)
  }, [])

  useEffect(() => {
    map?.invalidateSize()
    const gpsPoint = form.getFieldValue('gps_point')
    if (gpsPoint) {
      const newCenter = gpsPoint ? gpsPoint.split(' ').reverse() : null
      setCenter(newCenter)
      map?.setView(newCenter, map.getZoom())
    }
  }, [mapVisible, map])

  const onGpsChange = (e) => {
    if (/^-?[0-9]+\.?[0-9]* -?[0-9]+\.?[0-9]*$/g.test(e.target.value)) {
      const newCenter = e.target.value.split(' ').reverse()
      setCenter(newCenter)
    }
  }

  const LocationFinderDummy = () => {
    useMapEvents({
      click (e) {
        form.setFieldsValue({ gps_point: `${e.latlng.lng} ${e.latlng.lat}` })
        setCenter([e.latlng.lat, e.latlng.lng])
      }
    })
    return null
  }

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e[0].originFileObj
    }
    return e && e.fileList[0].originFileObj
  }

  const beforeUpload = () => {
    return false
  }

  const fileList = form.getFieldValue('picture_url')?.url
    ? [
        {
          url: form.getFieldValue('picture_url')?.url
        }
      ]
    : []

  const filteredInterveners = (interveners, job, displayedInterveners) => {
    if (job) {
      return interveners?.filter(i => i.job === job && !displayedInterveners.map(e => e.id).includes(i.id))
    } else {
      return interveners?.filter(i => !displayedInterveners.map(e => e.id).includes(i.id))
    }
  }

  return (
    <Form
      id="boxForm"
      form={form}
      labelAlign='left'
      layout='vertical'
      onFinish={onSubmit}
      disabled={state === 'show'}
      initialValues={{ interveners: [] }}
    >
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item name="name" label={t('Admin.BoxForm.name')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <h3>{t('Admin.BoxForm.group.address')}</h3>
          <Row gutter={[16, 16]}>
            <Col>
              <Form.Item name="city" label={t('Admin.BoxForm.city')}
                rules={[{ required: true, message: t('rules.required') }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="zip_code" label={t('Admin.BoxForm.zip_code')}
                rules={[{ required: true, message: t('rules.required') }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="address" label={t('Admin.BoxForm.address')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="extra_address" label={t('Admin.BoxForm.extra_address')}>
            <Input />
          </Form.Item>
          <Form.Item name="gps_point" label={t('Admin.BoxForm.gps')}
            rules={[{ required: true, pattern: /^-?[0-9]+\.?[0-9]* -?[0-9]+\.?[0-9]*$/g }]}
          >
            <Input onChange={onGpsChange} />
          </Form.Item>
          {mapVisible && <MapContainer
            ref={setMap}
            style={{ borderRadius: '2em', height: '200px', width: '100%' }} center={center || [48.856614, 2.3522219]} zoom={13} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {center && <CircleMarker center={center} radius={8} pathOptions={{ color: Color.white, fillColor: Color.red, weight: 2, fillOpacity: 1 }}></CircleMarker>}
            <LocationFinderDummy />
          </MapContainer>}
          <h3></h3>
          <Form.Item
            name="picture"
            label={t('Admin.BoxForm.picture')}
            getValueFromEvent={getFile}
            rules={[{ required: true, message: t('rules.required') }]}
          >
            <Upload name="picture"
              beforeUpload={beforeUpload}
              listType="picture"
              maxCount={1}
              defaultFileList={[...fileList]}
              showUploadList={{ showRemoveIcon: false }}
              accept='.jpg,.png'>
              <Button icon={<UploadOutlined />}>{t('Admin.BoxForm.pictureButton')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="owner_id" label={t('Admin.BoxForm.owner')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Select
              showSearch
              placeholder="Selectionner une collectivité"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={owners?.map((o) => ({ label: o.name, value: o.id }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <h3>{t('Admin.BoxForm.group.contact')}</h3>
          <Row gutter={[16, 16]}>
            <Col>
              <Form.Item name="interphone" label={t('Admin.BoxForm.interphone')}
                rules={[
                  {
                    validator: (_, value) =>
                      value === undefined || isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject(new Error('Numéro incorrect'))
                  }
                ]}>
                <PhoneInput country='FR' className='ant-input css-dev-only-do-not-override-xfszwz' disabled={state === 'show'} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="emergency_code" label={t('Admin.BoxForm.emergency_code')}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="pcc_phone" label={t('Admin.BoxForm.pcc_phone')}
                rules={[
                  {
                    validator: (_, value) =>
                      value === undefined || isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject(new Error('Numéro incorrect'))
                  }
                ]}>
                <PhoneInput country='FR' className='ant-input css-dev-only-do-not-override-xfszwz' disabled={state === 'show'} />
              </Form.Item>
            </Col>
          </Row>
          <h3>{t('Admin.BoxForm.group.interveners')}</h3>
          <Row style={{ marginBottom: '2em' }}>
            <Select
              showSearch
              allowClear
              placeholder="Filtrer par fonction"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={JOBS.map((o) => ({ label: t(`Admin.IntervenersList.table.job.${o}`), value: o }))}
              onSelect={(value) => setJob(value)}
              onClear={() => setJob(null)}
              value={job}
              style={{ width: '18em' }}
            />
            <Select
              showSearch
              placeholder="Rechercher un intervenant"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filteredInterveners(interveners, job, displayedInterveners).map((o) => ({ label: o.name, value: o.id }))}
              onSelect={(value) => setIntervener(value)}
              value={intervener}
              style={{ width: '23em' }}
            />
            <Button disabled={!intervener} onClick={addIntervener} type="primary" style={{ marginLeft: '2em' }} shape="circle" icon={<PlusOutlined />} />
          </Row>
          {displayInterveners()}
          <Form.Item name="comment" label={t('Admin.BoxForm.comment')}>
            <TextArea rows={20} />
          </Form.Item>
          <Form.Item name="video_surveillance_url" label={t('Admin.BoxForm.video_surveillance_url')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <a href={form.getFieldValue('video_surveillance_url')} target="_blank" rel="noreferrer">{t('Admin.BoxForm.video_surveillance_url')}</a>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <h3>{t('Admin.BoxForm.group.maintenance')}</h3>
          <Form.Item name="active">
            <Radio.Group>
              {state !== 'create' &&
                <Radio value={true} >{t('Admin.BoxForm.active')}</Radio>
              }
              <Radio value={false} >{t('Admin.BoxForm.inactive')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="calendoc_id" label={t('Admin.BoxForm.calendoc_id')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="nordnet_number" label={t('Admin.BoxForm.nordnet_number')}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item name="nordnet_email" label={t('Admin.BoxForm.nordnet_email')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <h3>{t('Admin.BoxForm.locks')}</h3>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="lock_1" label={t('Admin.BoxForm.lock_1')}>
                <Input />
              </Form.Item>
              <Form.Item name="lock_2" label={t('Admin.BoxForm.lock_2')}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="lock_3" label={t('Admin.BoxForm.lock_3')}>
                <Input />
              </Form.Item>
              <Form.Item name="lock_4" label={t('Admin.BoxForm.lock_4')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form >
  )
}
