import React from 'react'
import { t } from 'i18next'
import { Input, Select } from 'antd'
import { JOBS } from '../../Common/Common'

export default function IntervenerFilter ({ filter, onChangeFilter, boxes }) {
  const onChange = (hash) => {
    onChangeFilter({ ...filter, ...hash })
  }
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Select
        showSearch
        allowClear={true}
        placeholder={t('Admin.IntervenerFilter.box')}
        style={{ width: '250px' }}
        optionFilterProp="children"
        onChange={(value) => onChange({ box_id: value || '' })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={boxes.map((o) => ({ label: o.name, value: o.id }))}
      />
      <Select
        showSearch
        allowClear={true}
        placeholder={t('Admin.IntervenerFilter.job')}
        style={{ width: '250px' }}
        optionFilterProp="children"
        onChange={(value) => onChange({ job: value || 'all' })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={JOBS.map((job) => ({ label: t(`Admin.IntervenersList.table.job.${job}`), value: job }))}
      />
      <Input
        style={{ width: '250px' }}
        onChange={(e) => { onChange({ name: e.target.value }) }} placeholder={t('Admin.IntervenerFilter.name')}
        value={filter.name}
      />
    </div>
  )
}
