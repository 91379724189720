import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button, Tag } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { SLOT_COLORS } from '../../Common/Color'

const columns = () => [
  {
    title: t('Admin.SlotList.table.name'),
    dataIndex: 'name',
    key: 'name',
    width: 200,
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: t('Admin.SlotList.table.box'),
    dataIndex: 'box.name',
    key: 'box.name',
    render: (_, o) => o.box.name,
    width: 200,
    sorter: (a, b) => a.box.name.localeCompare(b.box.name)
  },
  {
    width: 120,
    title: t('Admin.SlotList.table.slotType'),
    dataIndex: 'slot_type',
    key: 'slot_type',
    render: (_, o) => <Tag color={SLOT_COLORS[o.slot_type]}>{t(`Admin.Slot.slotType.${o.slot_type}`)}</Tag>,
    sorter: (a, b) => a?.slot_type.localeCompare(b?.slot_type)
  },
  {
    width: 100,
    title: t('Admin.SlotList.table.range'),
    dataIndex: 'range',
    key: 'range',
    render: (_, o) => `${dayjs(o?.begin_date).format('H[h]')}-${dayjs(o?.end_date).format('H[h]')}`,
    sorter: (a, b) => dayjs(a.begin_date) - dayjs(b.begin_date)
  },
  {
    width: 150,
    title: t('Admin.SlotList.table.phone'),
    dataIndex: 'phone',
    key: 'phone',
    sorter: (a, b) => a.phone - b.phone
  },
  {
    width: 150,
    title: t('Admin.SlotList.table.state'),
    dataIndex: 'state',
    key: 'state',
    render: (_, o) => <Tag color={t(`Admin.Slot.stateColor.${o.state}`)}>{t(`Admin.Slot.state.${o.state}`)}</Tag>,
    sorter: (a, b) => a?.state.localeCompare(b?.state)
  },
  {
    width: 100,
    title: t('Admin.SlotList.table.digicode'),
    dataIndex: 'digicode',
    key: 'digicode',
    sorter: (a, b) => a?.digicode.localeCompare(b?.digicode)
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/slots/${o.id}`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
]

export default function SlotList ({ slots }) {
  return (
    <CustTable
      height={'calc(100vh - 230px)'}
      initColumns={columns()}
      dataSource={slots}
    />
  )
}
