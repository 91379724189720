import { Button, Layout, Modal, Popover, Spin, Tag } from 'antd'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { PhoneOutlined, TableOutlined } from '@ant-design/icons'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import BoxContext from './BoxContext'
import { useForm } from 'antd/es/form/Form'
import SlotForm from './SlotForm'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { Color, SLOT_COLORS } from '../../Common/Color'
import { Link } from 'react-router-dom'
import AvailableNowCard from './AvailableNowCard'
import CreateEventCard from '../EventsPage/CreateEventCard'
import PeriodForm from '../PeriodPage/PeriodForm'
import CreatePeriodCard from '../PeriodPage/CreatePeriodCard'

dayjs.extend(weekday)
dayjs.extend(localeData)

const title = (slot) => {
  const name = `${slot.first_name} ${slot.last_name}`
  if (slot.slot_type === 'clean') {
    return `[Nettoyage] ${name}`
  } else if (slot.slot_type === 'maintenance') {
    return `[Maintenace] ${name}`
  } else if (slot.slot_type === 'patient') {
    return `${name}`
  }
}

const transformSlot = (slot) => {
  return {
    id: slot.id,
    type: 'slot',
    start: dayjs(slot.begin_date).format(),
    end: dayjs(slot.end_date).subtract(1, 'second').format(),
    color: SLOT_COLORS[slot.slot_type],
    title: title(slot),
    className: !slot.sms_confirmed && 'slot-stripped'
  }
}

const transformPeriod = (day, period) => {
  return {
    id: period.id,
    type: 'period',
    periodType: period.period_type,
    weekDay: period.week_day,
    color: Color.period_open,
    title: t('Admin.BoxCalendar.periodTitle.open'),
    start: periodDate(day.date, period.start).format(),
    end: periodDate(day.date, period.end).format(),
    className: 'period-agenda-calendar-event'
  }
}

const periodDate = (day, date) => {
  return dayjs(day).set('minute', 0).set('second', 0).set('hour', dayjs(date).hour())
}

const getMinMaxHour = (days) => {
  let minHour = null
  let maxHour = null
  days.forEach((day) => {
    day.periods.forEach((period) => {
      if (minHour === null || dayjs(period.start).hour() < minHour) {
        minHour = dayjs(period.start).hour()
      }
      if (maxHour === null || dayjs(period.end).hour() > maxHour) {
        maxHour = dayjs(period.end).hour()
      }
    })
  })
  minHour -= 2
  maxHour += 2
  return [minHour, maxHour]
}

const businessHours = (days) => {
  const hours = []
  if (!days) { return hours }
  days.forEach((day) => (
    day.periods.filter((p) => p.period_type === 'open').map((period) => (
      hours.push({
        daysOfWeek: [day.week_day],
        startTime: dayjs(period.start).format('HH:mm'),
        endTime: dayjs(period.end).format('HH:mm')
      })
    ))
  ))
  return hours
}

const slotContent = (slot) => (
  <>
    <Tag color={t(`Admin.Slot.stateColor.${slot?.state}`)}>{t(`Admin.Slot.state.${slot?.state}`)}</Tag>
    <br />
    <PhoneOutlined /> <i>{slot?.phone}</i> <TableOutlined /> <i>{slot?.digicode}</i>
  </>
)

export default function BoxCalendarPage () {
  const { slots, loadSlots, box, saveSlot, loadDays, days, loading, savePeriod, deletePeriod } = useContext(BoxContext)
  const [slotModalVisible, setSlotModalVisible] = useState(false)
  const [rangeDisable, setRangeDisable] = useState(false)
  const [events, setEvents] = useState([])
  const [slotForm] = useForm()
  const [periodForm] = useForm()

  useEffect(() => {
    updateEvents(slots, days)
  }, [slots, days])

  const updateEvents = (slots, days) => {
    const formattedSlots = slots?.map((slot) => transformSlot(slot))
    const formattedDays = days?.map((day) => day.periods.map((period) => transformPeriod(day, period))).flat()
    setEvents([...formattedDays, ...formattedSlots])
  }

  const checkBoxAvailablity = () => {
    const now = dayjs()
    const slot = slots.find((slot) =>
      dayjs(slot.begin_date).isBefore(now) && dayjs(slot.end_date).isAfter(now)
    )
    const nextSlot = slots.find((slot) =>
      dayjs(slot.begin_date).isBefore(now.add(1, 'hour')) && dayjs(slot.end_date).isAfter(now.add(1, 'hour'))
    )
    return !(slot || nextSlot)
  }

  const getSlot = (id) => (
    slots.find((slot) => slot.id === id)
  )

  const renderEventContent = (eventInfo) => (
    <>
      { eventInfo.event.extendedProps.type === 'period' &&
        <div style={{ whiteSpace: 'nowrap', height: '100%' }} onClick={() => displayPeriodModal(eventInfo.event)} >
          {eventInfo.event.title}
        </div>
      }
       { eventInfo.event.extendedProps.type === 'slot' &&
        <Popover content={() => slotContent(getSlot(eventInfo.event.id))} title={eventInfo.event.title}>
          <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/admin/slots/${eventInfo.event.id}`}>
            <div style={{ whiteSpace: 'nowrap' }} >
              {eventInfo.event.title}
            </div>
          </Link>
        </Popover>
      }
    </>
  )

  const displayPeriodModal = (period) => {
    periodForm.resetFields()
    periodForm.setFieldsValue({
      period_type: period.extendedProps.periodType,
      week_day: period.extendedProps.weekDay,
      range: [dayjs(period.start), dayjs(period.end)],
      id: period.id
    })
    modal()
  }

  const selected = (info) => {
    slotForm.resetFields()
    slotForm.setFieldsValue({
      slot_type: 'patient',
      range: [dayjs(info.start), dayjs(info.end)],
      is_now: false,
      appointment_date: dayjs(info.start)
    })
    setRangeDisable(false)

    setSlotModalVisible(true)
  }

  const modal = () => Modal.confirm({
    icon: <></>,
    title: t('Admin.BoxCalendar.updatePopupTitle'),
    width: '600px',
    okText: t('actions.validate'),
    confirmLoading: loading,
    maskClosable: true,
    autoFocusButton: null,
    footer: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={onDelete} type="primary">{t('actions.delete')}</Button>
      <div>
        <Button onClick={() => Modal.destroyAll()} style={{ marginRight: '1em' }}>{t('actions.cancel')}</Button>
        <Button onClick={onFinish} type="primary">{t('actions.validate')}</Button>
      </div>
    </div>,
    content: <Spin spinning={loading}>
      <PeriodForm form={periodForm} />
    </Spin>
  })

  const onDelete = async () => {
    await deletePeriod(periodForm.getFieldValue('id'), () => {
      updateEvents(slots, days)
      Modal.destroyAll()
    })
  }

  const onFinish = async () => {
    await savePeriod(periodForm.getFieldsValue(), periodForm, () => {
      updateEvents(slots, days)
      Modal.destroyAll()
    })
  }

  const onAdd = async (values) => {
    values = { ...values, phone: values.phone.slice(1) }
    await saveSlot(values, slotForm, () => setSlotModalVisible(false))
  }

  const loadCalendarData = async (params) => {
    await loadSlots(box.id, params.start)
    await loadDays(box.id, params.start)
  }

  const boxAvailableNow = checkBoxAvailablity()
  const [minHour, maxHour] = getMinMaxHour(days)
  return (
    <>
      {box && <>
        <Modal
          open={slotModalVisible}
          width="800px"
          title={t('Admin.BoxCalendar.popupTitle')}
          okText={t('actions.validate')}
          confirmLoading={loading}
          onOk={slotForm.submit}
          onCancel={() => setSlotModalVisible(false)}
          >
          <Spin spinning={loading}>
            <SlotForm form={slotForm} onFinish={onAdd} rangeDisable={rangeDisable} />
          </Spin>
        </Modal>
        <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
          <div style={{ display: 'flex' }}>
            {
              boxAvailableNow && <AvailableNowCard setModalVisible={setSlotModalVisible} setRangeDisable={setRangeDisable} form={slotForm} />
            }
            <CreateEventCard box={box}/>
            <CreatePeriodCard days={days} loadCalendarData={loadCalendarData} />
          </div>
          {
            box.id && <FullCalendar
            height="auto"
            selectable={true}
            plugins={[timeGridPlugin, interactionPlugin]}
            firstDay={dayjs().day() - 1}
              initialView="timeGridWeek"
              slotDuration="01:00:00"
              locale={frLocale}
              allDaySlot={false}
              displayEventTime={false}
              businessHours={businessHours(days)}
              events={events}
              eventContent={renderEventContent}
              datesSet={loadCalendarData}
              select={(info) => selected(info)}
              slotMinTime={minHour + ':00:00'}
              slotMaxTime={maxHour + ':00:00'}
            />
          }
        </Layout>
      </>
      }
    </>
  )
}
